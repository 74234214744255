<template>
    <div class="settings-class--container">
        <div class="settings-class--inner-container">
            <div class="class--list">
                <div class="class--list-item" v-for="myClass of classes">
                    <p>{{myClass.fullName}}  {{(myClass.type === 1) ? "группа" : "класс"}}</p>
                </div>
                <div class="class--list-item add" v-if="$route.name != 'SettingsClassesAddView' && classes.length !== 4" @click="goToAdd" :class="{'non' : this.profile._payStatus === 0}">
                    <p>Добавить</p>
                    <img class="icon__add" src="../../../assets/icons/add.svg">
                </div>
            </div>
            <div class="class-router--container">
              <div class="text--container" v-if="$route.name === 'SettingsClassesView'" ref="container">
                <img src="../../../assets/img/ava.374665b0.png" class="avatar"  @load="onLoadedImage">
                <p class="text" v-if="classes.length < 4">Ваши дети учатся в разных группах или классах? <br>Воспользуйтесь функцией объединения аккаунтов и оплачивайте тариф со <strong>скидкой 50%</strong> на каждый добавленный профиль ребенка
                </p>
                <p class="text" v-else>Вы достигли максимального количества учеников в профиле</p>
              </div>
                <router-view v-else></router-view>
            </div>
        </div>
        <warning-expired-at-modal></warning-expired-at-modal>
    </div>
</template>

<script>
    import WarningExpiredAtModal from "@/components/modal/WarningExpiredAtModal";
    export default {
        name: "SettingsClassesView",
        components: {WarningExpiredAtModal},
        computed: {
            classes(){
                const profile =  this.$store.getters.PROFILE
                if (profile == null) return [];

                return profile.myClasses;
            },
            profile(){
                return this.$store.getters.PROFILE
            }
        },
        methods:{
            goToAdd(){
                if (this.profile._payStatus === 0){
                    this.$modal.show("WarningExpiredAtModal")
                } else {
                    this.$router.push({name : 'SettingsClassesAddView'})
                }
            },

          onLoadedImage(){
            this.$refs.container.classList.add("fade-in-bottom")
          }
        },
        async mounted(){
            await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
        }
    }
</script>

<style scoped lang="scss">
    .settings-class--container{
        width: 100%;
        height: 100%;
        margin-top: 14px;

    .settings-class--inner-container{
        @include for-desktop-devices{
            width: 385px;
            height: 700px;
            margin-left: 60px;
            margin-top: 26px;
            background: #F8F8F8;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
            border-radius: 4px;
        }

        .class--list{
            width: 100%;
            height: auto;
            border-bottom: 1px solid #C9C9C9;
            display: flex;
            flex-direction: column;
            background: #f8f8f8;



            .class--list-item{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 44px;
                border-top: 1px solid #C9C9C9;
                background: #f8f8f8;





                p{
                    margin-left: 20px;
                    font-family: Roboto, "sans-serif";
                    font-size: 15px;
                    letter-spacing: 0;
                    color: #222222;
                }

                img{
                    margin-right: 24px;
                }
            }

            .class--list-item:first-child{
                @include for-desktop-devices{
                    border-top: 0;
                }
            }

            .class--list-item.non{
                opacity: .5;
            }

            .class--list-item.add{
                background: white;
            }

            .class--list-item.add:active{
                opacity: 0.75;
            }
        }

        .class-router--container{
            margin-top: 23px;
            width: 100%;

          .text--container{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            opacity: 0;

            .avatar{
              width: 48px;
              height: 48px;
              margin-right: 5px;
              min-width: 48px;
              min-height: 48px;
            }

            p{
              font-family: Roboto, "sans-serif";
              font-size: 14px;
              color: #222222;
              line-height: 23px;
              letter-spacing: 0;
              padding-bottom: 18px;
              padding-top: 18px;
              padding-left: 18px;
              padding-right: 16px;
              background: #FFFFFF;
              box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16);
              border-radius: 8px;
              width: 299px;
              margin-right: 8px;

              strong{
                font-weight: 600;
              }
            }
          }
        }
    }
    }



    .fade-in-bottom {
      -webkit-animation: fade-in-bottom 0.2s ease-in 0.5s both;
      animation: fade-in-bottom 0.2s ease-in 0.5s both;
    }

    /* ----------------------------------------------
     * Generated by Animista on 2021-5-25 17:59:16
     * Licensed under FreeBSD License.
     * See http://animista.net/license for more info.
     * w: http://animista.net, t: @cssanimista
     * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation fade-in-bottom
     * ----------------------------------------
     */
    @-webkit-keyframes fade-in-bottom {
      0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
    @keyframes fade-in-bottom {
      0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
      }
      100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
      }
    }
</style>
